/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Card, Label, Button } from "reactstrap";
import { getParticularCategory } from "../../services/category.service";

const CategoryView = (props) => {
  let history = useHistory();
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    if (props?.match?.params.id) {
      getCategory(props.match.params.id);
    }
  }, [props.match]);

  const getCategory = async (id) => {
    const res = await getParticularCategory(id);
    delete res.data.data["product"];

    const data = res.data.data;
    setCategoryData(data);
  };

  return (
    <div className="content-wrapper">
      <div className="store-detail-wrap">
        <div className="store-content">
          <div className="logo-image">
            <Card
              style={{ height: "100px", width: "100px", borderRadius: "10px" }}
            >
              <img
                src={
                  categoryData.product_category_image
                    ? process.env.REACT_APP_API_URL +
                      categoryData.product_category_image
                    : ""
                }
              />
            </Card>
          </div>
          <div className="store-detail-content">
            <div className="category-name">
              <Label>Name:</Label>
              <p>{categoryData.product_category_name}</p>
            </div>
            <div className="card-footer">
              <span>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    history.push("/category");
                  }}
                >
                  Back
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryView;
