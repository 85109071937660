import { axiosGet, axiosPost } from "../axios/config";

export const getForgetPasswordData = (data) => {
  return axiosGet(`/forget_password/fetch_data`, data);
};

export const forgetPassword = (data) => {
  return axiosPost("/forget_password", data);
};

export const forgetUpdatePassword = (data) => {
  return axiosPost("/forget_password/update_password", data);
};
