/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { ToastShow } from '../../../redux/toast/toast.action';
import { Input, InputGroup, Card, CardBody, Button } from 'reactstrap';
import {
  forgetUpdatePassword,
  getForgetPasswordData,
} from '../../../services/forget_password.service';
import { createStructuredSelector } from 'reselect';
import { selectThemeSetting } from '../../../redux/theme_setting/theme.selector';

const ForgetPassword = (props) => {
  const { setToast, history, theme } = props;
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (props?.match?.params?.token) {
      getForgetData(props.match.params.token);
    }
  }, [props.match.params.token]);

  const getForgetData = async (data) => {
    const res = await getForgetPasswordData({ token: data });
    if (res.data.response_type === 'success') {
      setUserId(res.data.data._id);
      return;
    } else if (res.data.response_type === 'error') {
      setToast(res.data.message);
      setTimeout(() => {
        history.push('/login');
      }, 1000);
    }
  };

  const passwordRegExp =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegExp, 'Password is not stronger')
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password dose not match')
      .when('password', {
        is: (password) => (password ? password.length > 0 : false),
        then: Yup.string().required('Please enter password'),
      }),
  });

  const onSubmit = async (values) => {
    setLoader(true);
    const value = { ...values, user_id: userId };
    delete value['confirmPassword'];
    const res = await forgetUpdatePassword(value);
    setToast(res.data.message);
    setLoader(false);
    history.push('/login');
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched }) => {
          return (
            <div className='login-screen'>
              <div className='wrapper'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12 left-img-wrap'>
                    <img
                      src={
                        theme?.banner_image
                          ? process.env.REACT_APP_API_URL + theme.banner_image
                          : '/images/Tambe_app_icn.png'
                      }
                      alt='logo'
                    ></img>
                  </div>

                  <div className='col-md-6 col-sm-12'>
                    <div className='hold-transition login-page'>
                      <div className='login-box'>
                        <div className='login-logo'>
                          <img src='/images/tambe_logo_2.png' alt='logo'></img>
                        </div>
                        <Card>
                          <CardBody className='login-card-body'>
                            <p className='login-box-msg'>Forget Password</p>
                            <Form>
                              <InputGroup className='mb-3'>
                                <Field
                                  as={Input}
                                  type='password'
                                  placeholder='Password'
                                  name='password'
                                  invalid={
                                    touched.password && !!errors.password
                                  }
                                />
                                <div className='input-group-append'>
                                  <div className='input-group-text'>
                                    <span className='fas fa-lock'></span>
                                  </div>
                                </div>
                              </InputGroup>

                              <InputGroup className='mb-3'>
                                <Field
                                  as={Input}
                                  type='password'
                                  placeholder='Confirm Password'
                                  name='confirmPassword'
                                  invalid={
                                    touched.confirmPassword &&
                                    !!errors.confirmPassword
                                  }
                                />
                                <div className='input-group-append'>
                                  <div className='input-group-text'>
                                    <span className='fas fa-lock'></span>
                                  </div>
                                </div>
                              </InputGroup>
                              <div className='row'>
                                <div className='col-12'>
                                  <Button
                                    color='primary'
                                    block
                                    type='submit'
                                    disabled={loader ? true : false}
                                  >
                                    {loader ? (
                                      <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                                    ) : (
                                      ''
                                    )}
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  theme: selectThemeSetting,
});

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
