import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer text-center">
      <strong>Copyright @ <span>Tambe</span>.</strong>
    </footer>
  );
};

export default Footer;
