/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, CardBody, Button, FormGroup, Label, InputGroup, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { scrapStore } from "../../services/store.service";

import { connect } from "react-redux";
import { ToastShow } from "../../redux/toast/toast.action";

const initial_state = {
    store_link: ""
};

const ScrapForm = props => {
    const { setToast } = props;
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [initialValues, setInitialValues] = useState(initial_state);

    const validationSchema = Yup.object().shape({
        store_link: Yup.string().url("Invalid url").required("Link  is required")
    });

    const submitEvent = async value => {
        setLoader(true);
        const formData = new FormData();
        formData.append("store_link", value.store_link);
        const res = await scrapStore(formData);
        if (res.data.response_type === "success") {
            setToast("Success Scrap!!!");
            history.push("/store");
        }
        setLoader(false);
        setToast(res.data.message);
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={values => {
                submitEvent(values);
            }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <div className="content-wrapper">
                        <Form>
                            <CardBody>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <FormGroup>
                                            <Label htmlFor="URL">Enter URL</Label>
                                            <Field
                                                as={Input}
                                                type="text"
                                                placeholder="URL"
                                                name="store_link"
                                            />
                                            <ErrorMessage name="store_link">
                                                {msg => <span style={{ color: "red" }}>{msg}</span>}
                                            </ErrorMessage>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <div className="card-footer text-center">
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loader ? true : false}
                                >
                                    {loader ? (
                                        <i className="fas fa-3x fa-sync-alt fa-spin mr-2"></i>
                                    ) : (
                                        ""
                                    )}
                                    Scrap
                                </Button>
                                <span className="ml-3">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => {
                                            history.push("/category");
                                        }}
                                    >
                                        Back
                                    </Button>
                                </span>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

const mapDispatchToProps = dispatch => ({
    setToast: message => dispatch(ToastShow({ message }))
});

export default connect(null, mapDispatchToProps)(ScrapForm);
