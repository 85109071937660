import TostActionTypes from "./toast.type";

const INITIAL_STATE = {
  message:'',
}

const toastReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case TostActionTypes.SET_TOAST:
      return{
        ...state,
        message:action.payload
      }
    default:
      return state
  }
}

export default toastReducer;