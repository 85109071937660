/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  CardBody,
  Button,
  FormGroup,
  Label,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DropZone from '../../common/dropzone';
import { connect } from 'react-redux';
import { ToastShow } from '../../redux/toast/toast.action';
import {
  addThemeSetting,
  getThemeSetting,
} from '../../services/theme_setting.service';
import { setThemeSetting } from '../../redux/theme_setting/theme.action';

const initial_state = {
  logo_image: [],
  banner_image: [],
};

const ThemeSetting = (props) => {
  const { setToast, setThemeSetting } = props;
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(initial_state);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    const res = await getThemeSetting();
    if (res.data.response_type === 'success') {
      const data = res.data.data;
      if (data !== []) {
        if (data.banner_image || data.logo_image) {
          setInitialValues({
            ...initialValues,
            banner_image: data.banner_image
              ? [
                  {
                    name: data._id,
                    preview: process.env.REACT_APP_API_URL + data.banner_image,
                  },
                ]
              : [],
            logo_image: data.logo_image
              ? [
                  {
                    name: data._id,
                    preview: process.env.REACT_APP_API_URL + data.logo_image,
                  },
                ]
              : [],
          });
        } else {
          setInitialValues({
            ...initialValues,
            banner_image: [],
            logo_image: [],
          });
        }
      }
    }
  };

  const submitEvent = async (value) => {
    console.log(value);
    setLoader(true);
    const formData = new FormData();
    let bannerObj = [];
    let logoObj = [];
    if (value.logo_image) {
      value.logo_image.forEach((p) => {
        let logoImage = [];
        if (p) {
          if (!p.hasOwnProperty('path')) {
            logoImage.push(p);
          } else {
            formData.append('logo_image', p);
          }
        }
        if (logoImage.length > 0) {
          logoObj.push({
            logoImage,
          });
        }
      });
    }
    if (logoObj.length > 0)
      formData.append('logo_image', JSON.stringify(logoObj));

    if (value.banner_image) {
      value.banner_image.forEach((p) => {
        let bannerImage = [];
        if (p) {
          if (!p.hasOwnProperty('path')) {
            bannerImage.push(p);
          } else {
            formData.append('banner_image', p);
          }
        }
        if (bannerImage.length > 0) {
          bannerObj.push({
            bannerImage,
          });
        }
      });
    }
    if (bannerObj.length > 0)
      formData.append('banner_image', JSON.stringify(bannerObj));

    const res = await addThemeSetting(formData);
    if (res.data.response_type === 'success') {
      setToast('Theme Update!!!');
      setThemeSetting(res.data.data);
      history.push('/');
    }
    setLoader(false);
    setToast(res.data.message);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        submitEvent(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className='content-wrapper'>
            <Form>
              <CardBody>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor='banner image'>Banner Image</Label>
                      <InputGroup>
                        <DropZone
                          name='banner_image'
                          files={values.banner_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor='logo image'>Logo Image</Label>
                      <InputGroup>
                        <DropZone
                          name='logo_image'
                          files={values.logo_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <div className='card-footer text-center'>
                <Button
                  type='submit'
                  color='primary'
                  disabled={loader ? true : false}
                >
                  {loader ? (
                    <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                  ) : (
                    ''
                  )}
                  Update Theme
                </Button>
                <span className='ml-3'>
                  <Button
                    type='button'
                    color='secondary'
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Back
                  </Button>
                </span>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
  setThemeSetting: (theme) => dispatch(setThemeSetting(theme)),
});

export default connect(null, mapDispatchToProps)(ThemeSetting);
