/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Input, CardBody, Button, FormGroup, Label, InputGroup, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { createProduct, getParticularProduct, updateProduct } from "../../services/product.service";
import { getAllStores } from "../../services/store.service";
import { getAllProductCategory } from "../../services/category.service";
import DropZone from "../../common/dropzone";
import { ToastShow } from "../../redux/toast/toast.action";
import { connect } from "react-redux";

const initial_state = {
    store_id: "",
    product_name: "",
    product_image: [],
    product_desc: "",
    product_price: "",
    product_material: "",
    product_size: [],
    product_category: [],
    product_colors: [{ color: "#000000", image: "" }],
    is_similar: false,
    product_link: "",
    sort: ""
};

const sizeOption = [
    {
        value: "S",
        label: "S"
    },
    {
        value: "M",
        label: "M"
    },
    {
        value: "L",
        label: "L"
    }
];

let originalStoreComboOption = [];

const ProductForm = props => {
    const { setToast } = props;
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [initialValues, setInitialValues] = useState(initial_state);
    const [id, setId] = useState(-1);
    const [store, setStore] = useState([]);
    const [productCategory, setProductCategory] = useState([]);

    useEffect(() => {
        if (props?.match?.params.id) {
            setId(props.match.params.id);
            getProduct(props.match.params.id);
        }
    }, [props.match]);

    useEffect(() => {
        fetchStore();
        fetchCategory();
    }, []);

    const fetchStore = async () => {
        const res = await getAllStores();
        const options = res.data.data;
        let comboOptions = [];
        options.forEach(({ _id, store_name }) =>
            comboOptions.push({
                value: _id,
                label: store_name
            })
        );
        originalStoreComboOption = comboOptions;
        setStore(comboOptions);
    };

    const fetchCategory = async () => {
        const res = await getAllProductCategory();
        const options = res.data.data;
        let comboOptions = [];
        options.forEach(({ key, value }) =>
            comboOptions.push({
                value: value,
                label: key
            })
        );

        setProductCategory(comboOptions);
    };

    const getProduct = async id => {
        const res = await getParticularProduct(id);

        const data = res.data.data;

        const preview = data.product_image.map((e, index) => {
            return {
                name: index,
                preview: process.env.REACT_APP_API_URL + e
            };
        });

        if (data !== []) {
            if (data.product_image) {
                setInitialValues({
                    ...initialValues,
                    ...res.data.data,
                    product_image: preview
                });
            } else {
                setInitialValues({
                    ...initialValues,
                    ...res.data.data,
                    product_image: []
                });
            }
        }
    };

    const validationSchema = Yup.object().shape({
        store_id: Yup.string().required("Please select store"),
        product_name: Yup.string()
            .min(3, `Product name has to be at least 2 characters`)
            .required("Product name is required"),
        product_desc: Yup.string().required("Description is required"),
        product_link: Yup.string().required("Link is required"),
        product_price: Yup.string().required("Price is required"),
        product_image: Yup.array().test("product_image", "Product image is required", value => {
            let toggle = true;
            if (!value || value.length === 0) {
                toggle = false;
            }
            return toggle;
        })
    });

    const handleChange = (sizes, setFieldValue) => {
        let sizeValues = [];
        if (sizes) {
            sizeValues = sizes.map(size => size.value);
        }
        setFieldValue("product_size", sizeValues);
    };

    const handleChangeCategoires = (categoires, setFieldValue) => {
        let categoryValues = [];
        if (categoires) {
            categoryValues = categoires.map(category => category.value);
        }
        setFieldValue("product_category", categoryValues);
    };

    const submitEvent = async value => {
        setLoader(true);
        const formData = new FormData();
        formData.append("product_name", value.product_name);
        formData.append("store_id", value.store_id);
        formData.append("product_desc", value.product_desc);
        formData.append("product_price", value.product_price);
        formData.append("product_link", value.product_link);
        formData.append("sort", value.sort);
        formData.append("product_material", value.product_material);
        formData.append("is_similar", value.is_similar);
        if (value.product_size) {
            value.product_size.forEach(v => {
                formData.append("product_size", v);
            });
        }

        if (value.product_category) {
            value.product_category.forEach(v => {
                formData.append("product_category", v);
            });
        }
        if (value.product_colors && value.product_colors.length > 0) {
            // Serialize the product_colors array
            formData.append("product_colors", JSON.stringify(value.product_colors));
        }
        let obj = [];
        if (value.product_image) {
            value.product_image.forEach(p => {
                let imageAlreadyStoreData = [];
                if (p) {
                    if (!p.hasOwnProperty("path")) {
                        const modifiedP = {
                            ...p,
                            preview: p.preview.replace(process.env.REACT_APP_API_URL, "")
                        };
                        imageAlreadyStoreData.push(modifiedP);
                    } else {
                        formData.append("product_image", p);
                    }
                }
                if (imageAlreadyStoreData.length > 0) {
                    obj.push({
                        imageAlreadyStoreData
                    });
                }
            });
        }
        if (obj.length > 0) formData.append("product_image", JSON.stringify(obj));

        if (id !== -1) {
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
            const res = await updateProduct(id, formData);
            if (res.data.response_type === "success") {
                setToast("Product Updated!!!");
                history.push("/product");
            }
            setLoader(false);
            setToast(res.data.message);
        } else {
            const res = await createProduct(formData);
            if (res.data.response_type === "success") {
                setToast("Product Created!!!");
                history.push("/product");
            }
            setLoader(false);
            setToast(res.data.message);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={values => {
                submitEvent(values);
            }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <div className="content-wrapper">
                        <Form>
                            <CardBody>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="store name">Store</Label>
                                            <Select
                                                name="store_id"
                                                placeholder="Select Store"
                                                options={store}
                                                value={originalStoreComboOption.filter(
                                                    data => data.value === values.store_id
                                                )}
                                                onChange={e => {
                                                    setFieldValue("store_id", e.value);
                                                }}
                                                isDisabled={id === -1 ? false : true}
                                            />
                                            <ErrorMessage name="store_id">
                                                {msg => <span style={{ color: "red" }}>{msg}</span>}
                                            </ErrorMessage>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product name">Name</Label>
                                            <Field
                                                as={Input}
                                                type="text"
                                                placeholder="Product Name"
                                                name="product_name"
                                            />
                                            <ErrorMessage name="product_name">
                                                {msg => <span style={{ color: "red" }}>{msg}</span>}
                                            </ErrorMessage>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product desc">Description</Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Product Description"
                                                name="product_desc"
                                                rows={7}
                                                value={values.product_desc}
                                                onChange={e =>
                                                    setFieldValue("product_desc", e.target.value)
                                                }
                                            />
                                            <ErrorMessage name="product_desc">
                                                {msg => <span style={{ color: "red" }}>{msg}</span>}
                                            </ErrorMessage>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product image">Product Image</Label>
                                            <InputGroup>
                                                <DropZone
                                                    name="product_image"
                                                    files={values.product_image}
                                                    setFiles={setFieldValue}
                                                    number={20}
                                                    id={-1}
                                                    multipleOption={true}
                                                />
                                            </InputGroup>
                                            <ErrorMessage name="product_image">
                                                {msg => <span style={{ color: "red" }}>{msg}</span>}
                                            </ErrorMessage>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product price">Price</Label>
                                            <Field
                                                as={Input}
                                                type="text"
                                                placeholder="Product Price"
                                                name="product_price"
                                            />
                                            <ErrorMessage name="product_price">
                                                {msg => <span style={{ color: "red" }}>{msg}</span>}
                                            </ErrorMessage>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product material">Material</Label>
                                            <Field
                                                as={Input}
                                                type="text"
                                                placeholder="Product Material"
                                                name="product_material"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <FieldArray
                                                name="product_colors"
                                                render={arrayHelpers => (
                                                    <div>
                                                        <Label htmlFor="product_colors">
                                                            Colors
                                                        </Label>
                                                        <div>
                                                            {values.product_colors?.length > 0 &&
                                                                values.product_colors.map(
                                                                    (item, index) => (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}
                                                                        >
                                                                            <Field
                                                                                as={Input}
                                                                                type="color"
                                                                                name={`product_colors.${index}.color`}
                                                                            />
                                                                            <Field
                                                                                as={Input}
                                                                                type="text"
                                                                                placeholder="Image Index"
                                                                                name={`product_colors.${index}.image`}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                style={{
                                                                                    border: "none",
                                                                                    borderRadius:
                                                                                        "50%"
                                                                                }}
                                                                                onClick={() =>
                                                                                    arrayHelpers.remove(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            color="secondary"
                                                            style={{ marginTop: "10px" }}
                                                            onClick={() =>
                                                                arrayHelpers.push({
                                                                    color: "#000000",
                                                                    image: ""
                                                                })
                                                            }
                                                        >
                                                            Add Color
                                                        </Button>
                                                    </div>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product size">Size</Label>
                                            <CreatableSelect
                                                isMulti
                                                options={sizeOption}
                                                name="product_size"
                                                placeholder="Select size"
                                                value={values.product_size.map(e => {
                                                    return {
                                                        label: e,
                                                        value: e
                                                    };
                                                })}
                                                onChange={e => {
                                                    handleChange(e, setFieldValue);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label htmlFor="product categories">
                                                Product Categories
                                            </Label>
                                            <CreatableSelect
                                                isMulti
                                                options={productCategory}
                                                name="product_category"
                                                placeholder="Select Product Categories"
                                                value={productCategory.filter(e => {
                                                    return values.product_category.find(
                                                        e1 => e.value === e1
                                                    );
                                                })}
                                                onChange={e => {
                                                    handleChangeCategoires(e, setFieldValue);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup className="m-2" check inline>
                                    <Field as={Input} type="checkbox" name="is_similar" />
                                    <Label className="mt-2 ml-3" htmlFor="product color">
                                        Smilliar Product
                                    </Label>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="product link">Link</Label>
                                    <Field
                                        as={Input}
                                        type="text"
                                        placeholder="Product Link"
                                        name="product_link"
                                    />
                                    <ErrorMessage name="product_link">
                                        {msg => <span style={{ color: "red" }}>{msg}</span>}
                                    </ErrorMessage>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="sort">Sort</Label>
                                    <Field
                                        as={Input}
                                        type="number"
                                        placeholder="sort"
                                        name="sort"
                                    />
                                </FormGroup>
                            </CardBody>
                            <div className="card-footer text-center">
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loader ? true : false}
                                >
                                    {loader ? (
                                        <i className="fas fa-3x fa-sync-alt fa-spin mr-2"></i>
                                    ) : (
                                        ""
                                    )}
                                    {id === -1 ? <>Add Product</> : <>Update Product</>}
                                </Button>
                                <span className="ml-3">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => {
                                            history.push("/product");
                                        }}
                                    >
                                        Back
                                    </Button>
                                </span>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

const mapDispatchToProps = dispatch => ({
    setToast: message => dispatch(ToastShow({ message }))
});

export default connect(null, mapDispatchToProps)(ProductForm);
