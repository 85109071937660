import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../axios/config";

export const login = user => {
    return axiosPost("/admin/login", user);
};

export const getAllUsers = data => {
    return axiosGet("/user/list", data);
};

export const getCurrentuser = data => {
    return axiosGet("/user/", data);
};

export const getUser = id => {
    return axiosGet(`/user/${id}`);
};

export const deleteUser = id => {
    return axiosDelete(`/user/delete/${id}`);
};

export const createUser = data => {
    return axiosPost("/user/add_user", data);
};

export const updateUser = (id, data) => {
    return axiosPatch(`/user/update/${id}`, data);
};
