import axios from 'axios';

const setupAxios = (store) => {
  axios.interceptors.request.use((request) => {
    const storeData = store.getState();
    const authToken = storeData.token;
    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }
    return request;
  });
};

export default setupAxios;

export const axiosGet = (url, data) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    params: data,
  });
};

export const axiosPost = (url, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosPatch = (url, data) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosPut = (url, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosDelete = (url) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}${url}`);
};
