import React from 'react';
import { useDropzone } from 'react-dropzone';
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #000000',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

function DropZone(props) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpeg,.jpg,.png',
    maxFiles: props.number,
    multiple: props.multipleOption,
    // acceptedFiles: ".jpeg,.jpg,.png",
    onDrop: (acceptedFiles) => {
      const imageDataOnDrop = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      if (props.id !== -1) {
        props.setFiles(`items[${props.id}].item_images`, [
          ...props.files,
          ...imageDataOnDrop,
        ]);
      } else if (props.id === -1) {
        if (props.multipleOption) {
          props.setFiles(props.name, [...props.files, ...imageDataOnDrop]);
        } else {
          props.setFiles(props.name, [...imageDataOnDrop]);
        }
      } else {
        props.setFiles([...props.files, ...imageDataOnDrop]);
      }
    },
  });

  const removeImage = (index) => {
    props.files.splice(index, 1);
    if (props.id !== -1) {
      props.setFiles(`items[${props.id}].item_images`, [...props.files]);
    } else if (props.id === -1) {
      props.setFiles(props.name, [...props.files]);
    } else {
      props.setFiles([...props.files]);
    }
  };

  return (
    <section className='dropzone_main'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag n' Drop File Here</p>
      </div>
      <aside style={thumbsContainer}>
        {props &&
          props.files.map((file, index) => {
            return (
              <div className='dz_img_wrp' key={file.name}>
                <div style={thumb}>
                  <div style={thumbInner}>
                    <img src={file.preview} style={img} alt='itemImage' />
                  </div>
                </div>
                <div className='remove_img'>
                  <span onClick={(e) => removeImage(index)}>
                    {/* remove image */}
                    <i className='fa fa-times-circle'></i>
                  </span>
                </div>
              </div>
            );
          })}
      </aside>
    </section>
  );
}
export default DropZone;
