/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { setToken } from '../../redux/token/token.action';
import { setCurrentUser } from '../../redux/user/user.action';
import { selectCurrentUser } from '../../redux/user/user.selector';

const Header = ({ currentUser, removeToken, removeUser }) => {
  const history = useHistory();
  const [showDropdown, setShowDropDown] = useState(false);

  const dropdownClick = () => {
    setShowDropDown(!showDropdown);
  };

  const editProfile = () => {
    history.push('/edit-profile');
  };

  const logout = () => {
    removeToken(null);
    removeUser(null);
    history.push('/login');
  };

  const handleBlur = () => {
    setShowDropDown(false);
    const data = document.getElementsByTagName('body')[0];
    if (window.innerWidth > 991) {
      return;
    }

    if (data.className === 'sidebar-open') {
      return (data.className = 'sidebar-closed sidebar-collapse');
    }
  };

  return (
    <nav
      className='main-header navbar navbar-expand navbar-white navbar-light'
      onBlur={handleBlur}
    >
      {/* <!-- Left navbar links --> */}
      <ul className='navbar-nav'>
        <li className='nav-item'>
          <a className='nav-link' data-widget='pushmenu' href='#' role='button'>
            <i className='fas fa-bars'></i>
          </a>
        </li>
      </ul>

      {/* <!-- Right navbar links --> */}
      <ul className='navbar-nav ml-auto'>
        {/* <!-- Signout Dropdown --> */}
        <li className={`nav-item dropdown${showDropdown ? ' show' : ''}`}>
          <a
            className='nav-link dropdown-toggle'
            data-toggle='dropdown'
            href='#'
            onClick={dropdownClick}
          >
            <img
              src={
                currentUser?.user_image
                  ? `${process.env.REACT_APP_API_URL + currentUser.user_image}`
                  : '/images/user_profile.jpg'
              }
              className='user-image'
              style={{ height: '35px', width: '35px' }}
              alt=''
            />
            {/* <i className="far fa-user"></i> */}
            <span className='ml-2'>
              {currentUser?.name
                ? currentUser.name.charAt(0).toUpperCase() +
                  currentUser.name.slice(1)
                : ''}
            </span>
          </a>
          <div
            className={`dropdown-menu dropdown-menu-right${
              showDropdown ? ' show' : ''
            }`}
          >
            <div
              className='dropdown-item'
              onMouseDown={() => history.push('/theme-setting')}
            >
              <i className='fas fa-cog mr-2'></i> Theme Setting
            </div>
            <div className='dropdown-divider'></div>
            <div className='dropdown-item' onMouseDown={editProfile}>
              <i className='fas fa-edit mr-2'></i> Edit Profile
            </div>
            <div className='dropdown-divider'></div>
            <div className='dropdown-item' onMouseDown={logout}>
              <i className='fas fa-power-off mr-2'></i> Logout
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  removeToken: (token) => dispatch(setToken(token)),
  removeUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
