import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import swal from "sweetalert";
import DataTable from "../../common/table/table";
import { ToastShow } from "../../redux/toast/toast.action";
import { deleteCategoty, getAllCategory } from "../../services/category.service";

const CategoryList = props => {
    const { setToast } = props;
    let history = useHistory();
    const [data, setData] = useState([]);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [loader, setLoader] = useState(true);

    const getCategoryData = async (page, paginationPerPage, searchValue) => {
        setLoader(true);

        let rows = await getAllCategory({ page, paginationPerPage, searchValue });
        if (rows.data.data) {
            const totalDataCount = rows.data.data.count;
            rows = rows.data.data.data;
            const tableData = rows.map(r => ({
                ...r,
                showButtons: true
            }));
            setData(tableData);
            setPaginationTotalRows(totalDataCount);
        }
        setLoader(false);
    };

    const editData = id => {
        history.push(`/edit-category/${id}`);
    };

    const viewData = id => {
        history.push(`/view-category/${id}`);
    };

    const viewProductData = id => {
        history.push(`/view-category-product/${id}`);
    };

    const deleteData = async id => {
        swal({
            text: "Are you sure that you want to delete this category?",
            buttons: true
        }).then(async willDelete => {
            if (willDelete) {
                let deletedData = await deleteCategoty(id);
                const arrangeData = data.filter(d => d._id !== id);
                deletedData && setData(arrangeData);
                setToast("Category deleted!!!");
            }
        });
    };

    const columns = [
        {
            name: "Name",
            selector: row => row["product_category_name"],
            sortable: true
        },
        {
            name: "Action",
            cell: row => {
                return (
                    <div style={{ display: "flex" }}>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={e => viewData(row._id)}
                            style={{ marginRight: "5px" }}
                        >
                            <i className="fa fa-eye"></i>
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={e => viewProductData(row._id)}
                            style={{ marginRight: "5px" }}
                        >
                            <i className="fab fa-product-hunt"></i>
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={e => editData(row._id)}
                            style={{ marginRight: "5px" }}
                        >
                            <i className="fa fa-edit"></i>
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={e => deleteData(row._id)}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                );
            }
        }
    ];

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Category</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-2 list-header">
                                        <div className="col-sm-6">
                                            <h4>All Categories</h4>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="float-right">
                                                <Link to="add-category">
                                                    <Button color="primary">Add Category</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {data && (
                                        <DataTable
                                            title="All Categories"
                                            columns={columns}
                                            data={data}
                                            paginationTotalRows={paginationTotalRows}
                                            fetchData={(page, paginationPerPage, searchValue) =>
                                                getCategoryData(
                                                    page,
                                                    paginationPerPage,
                                                    searchValue
                                                )
                                            }
                                            loader={loader}
                                            searchPlaceHolder="Search category..."
                                        ></DataTable>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setToast: message => dispatch(ToastShow({ message }))
});

export default connect(null, mapDispatchToProps)(CategoryList);
