/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../loader/index";
import Select from "react-select";

const TableComponent = (props) => {
  const rowOption = [
    {
      value: 10,
      label: 10,
    },
    {
      value: 25,
      label: 25,
    },
    {
      value: 50,
      label: 50,
    },
    {
      value: 100,
      label: 100,
    },
  ];

  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [paginationPerPage, setPaginationPerPage] = useState(10);

  useEffect(() => {
    setColumns([
      {
        name: "Sr. No",
        selector: (row, index) => (page - 1) * paginationPerPage + index + 1,
      },
      ...props.columns,
    ]);
  }, [props.columns]);

  useEffect(() => {
    props.fetchData && props.fetchData(page, paginationPerPage, searchValue);
  }, [page, paginationPerPage, searchValue]);

  useEffect(() => {
    if (props.data.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [props.data]);

  const handlePage = (page) => {
    setPage(page);
  };

  const searchHandle = (e) => {
    setPage(1);
    setSearchValue(e.target.value);
  };
  return (
    <div className="table_default">
      <div className="dataTable-search">
        <input
          name="search"
          type="text"
          onChange={(e) => searchHandle(e)}
          placeholder={props.searchPlaceHolder}
        />
        <img src="\iconmonstr-search-thin.svg"></img>
      </div>
      <div className="table_content">
        {props.data.length > 0 && (
          <div className="d-flex">
            <p className="mt-2">No. of rows : </p>
            <Select
              className="ml-3"
              placeholder="Select Store"
              options={rowOption}
              defaultValue={rowOption[0]}
              onChange={(e) => {
                setPaginationPerPage(e.value);
              }}
            />
          </div>
        )}
        {props.loader ? (
          <div className="table_loader">
            <Loader />
          </div>
        ) : (
          <DataTable
            // title={props.title}
            columns={columns}
            data={props.data}
            highlightOnHover
            striped={true}
            sortServer
            paginationDefaultPage={page}
            pagination
            paginationServer
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={paginationPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => handlePage(page)}
          ></DataTable>
        )}
      </div>
    </div>
  );
};

export default TableComponent;
