/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const FacebookDataDeletionInstruction = () => {
  return (
    <section class='policy-sec facebook-instruction'>
      <div class='container'>
        <div class='row'>
          <div class='col col-12'>
            <div class='title'>
              <h1>Facebook Data Deletion Instructions URL</h1>
              {/* <label class=''>Last updated February 20, 2022</label> */}
            </div>
          </div>
          <div class='col col-12'>
            <p>
              Tambe App is a facebook login app and we do not save your personal
              data in our server. According to Facebook policy, we have to
              provide User Data Deletion Callback URL or Data Deletion
              Instructions URL.
            </p>
          </div>
          <div class='col col-12'>
            <p>
              Tambe App is a facebook login app and we do not save your personal
              data in our server. According to Facebook policy, we have to
              provide User Data Deletion Callback URL or Data Deletion
              Instructions URL.
            </p>
            <span>
              If you want to delete your activities for Tambe App, you can
              remove your information by following these steps:
            </span>
            <ul>
              <li>
                Go to your Facebook Account’s Setting & Privacy. Click
                “Settings”.
              </li>
              <li>
                Look for “Apps and Websites” and you will see all of the apps
                and websites you linked with your Facebook.
              </li>
              <li>Search and Click “Tambe App ” in the search bar.</li>
              <li>Scroll and click “Remove”.</li>
              <li>
                Congratulations, you have successfully removed your app
                activities.
              </li>
            </ul>
            <span className='thank-you'>Thank You.!!</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FacebookDataDeletionInstruction;
