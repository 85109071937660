/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createStructuredSelector } from 'reselect';
import { ToastShow } from '../../redux/toast/toast.action';
import { selectToastMessage } from '../../redux/toast/toast.selector';

const ToastNotification = (props) => {
  const { toastMessage, removeToast } = props;

  useEffect(() => {
    if (toastMessage.message) {
      toast(toastMessage.message);
      setTimeout(() => {
        removeToast({ message: null });
      }, 500);
    }
  }, [toastMessage]);
  return (
    <div className='error-toast'>
      <ToastContainer
        position='top-right'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  toastMessage: selectToastMessage,
});

const mapDispatchToProps = (dispatch) => ({
  removeToast: (data) => dispatch(ToastShow(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotification);
