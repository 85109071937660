import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import tokenReducer from './token/token.reducer';
import toastReducer from './toast/toast.reducer';
import themeReducer from './theme_setting/theme.reducer';

const persistConfig = {
  key: 'tambe',
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  token: tokenReducer,
  toast: toastReducer,
  theme: themeReducer,
});

export default persistReducer(persistConfig, rootReducer);
