/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const TermsAndConditionDetails = () => {
  return (
    <section class='policy-sec'>
      <div class='container'>
        <div class='row'>
          <div class='col col-12'>
            <div class='title'>
              <h1>Terms & Condition</h1>
              <label class=''>Last updated: June 16, 2021</label>
            </div>
          </div>

          <div class='col col-12'>
            <div class='policy-content'>
              <h5>
                Please read these terms and conditions carefully before using
                Our Service.
              </h5>
              <h3>Interpretation and Definitions</h3>
              <h5>INTERPRETATION</h5>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <h5>DEFINITIONS</h5>
              <p>For the purposes of these Terms and Conditions:</p>
              <ul>
                <li>
                  <strong>Account</strong> means a unique account created for
                  You to access our Service or parts of our Service.
                </li>
                <li>
                  <strong>Company</strong> (referred to as either “the Company”,
                  “We”, “Us” or “Our” in this Agreement) refers to TAMBe.
                </li>
                <li>
                  <strong>Device</strong> means any device that can access the
                  Service such as a computer, a cellphone or a digital tablet.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>Terms and Conditions</strong> (also referred as
                  “Terms”) mean these Terms and Conditions that form the entire
                  agreement between You and the Company regarding the use of the
                  Service.
                </li>
                <li>
                  <strong>Third-party Social Media Service</strong> means any
                  services or content (including data, information, products or
                  services) provided by a third-party that may be displayed,
                  included or made available by the Service.
                </li>
                <li>
                  <strong>Website</strong> refers to TAMBe app, accessible from
                  https://tambe.app
                </li>
                <li>
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </li>
              </ul>
              <h5>Acknowledgment</h5>
              <p>
                These are the Terms and Conditions governing the use of this
                Service and the agreement that operates between You and the
                Company. These Terms and Conditions set out the rights and
                obligations of all users regarding the use of the Service.
              </p>
              <p>
                Your access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions.
                These Terms and Conditions apply to all visitors, users and
                others who access or use the Service.
              </p>
              <p>
                By accessing or using the Service You agree to be bound by these
                Terms and Conditions. If You disagree with any part of these
                Terms and Conditions then You may not access the Service.
              </p>
              <p>
                Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the
                Company. Our Privacy Policy describes Our policies and
                procedures on the collection, use and disclosure of Your
                personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects
                You. Please read Our Privacy Policy carefully before using Our
                Service.
              </p>
              <h5>User Accounts</h5>
              <p>
                When You create an account with Us, You must provide Us
                information that is accurate, complete, and current at all
                times.
              </p>
              <p>
                You are responsible for safeguarding the password that You use
                to access the Service and for any activities or actions under
                Your password, whether Your password is with Our Service or a
                Third-Party Social Media Service.
              </p>
              <p>
                You agree not to disclose Your password to any third party. You
                must notify Us immediately upon becoming aware of any breach of
                security or unauthorized use of Your account.
              </p>
              <h5>Intellectual Property</h5>
              <p>
                The Service and its original content (excluding Content provided
                by You or other users), features and functionality are and will
                remain the exclusive property of the Company and its licensors.
              </p>
              <p>
                The Contents of Store & Product data on the Service is not owned
                by the Company, this data is scraped from Store websites and is
                the intellectual property of each individual Store that the data
                is scraped from.
              </p>
              <p>
                The Service is protected by copyright, trademark, and other laws
                of both the Country and foreign countries.
              </p>
              <p>
                Our trademarks and trade dress may not be used in connection
                with any product or service without the prior written consent of
                the Company.
              </p>
              <h5>Links to Other Websites</h5>
              <p>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by the Company.
              </p>
              <p>
                The Company has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that the Company shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                such content, goods or services available on or through any such
                web sites or services.
              </p>
              <p>
                We strongly advise You to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                You visit.
              </p>
              <h5>Termination</h5>
              <p>
                We may terminate or suspend Your Account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if You breach these Terms and Conditions.
              </p>
              <p>
                Upon termination, Your right to use the Service will cease
                immediately. If You wish to terminate Your Account, You may
                simply disable your account from the Service and discontinue
                using the Service.
              </p>
              <h5>Governing Law</h5>
              <p>
                The laws of the Country, excluding its conflicts of law rules,
                shall govern this Terms and Your use of the Service. Your use of
                the Application may also be subject to other local, state,
                national, or international laws.
              </p>
              <h5>Disputes Resolution</h5>
              <p>
                If You have any concern or dispute about the Service, You agree
                to first try to resolve the dispute informally by contacting the
                Company.
              </p>
              <h5>For European Union (EU) Users</h5>
              <p>
                If You are a European Union consumer, you will benefit from any
                mandatory provisions of the law of the country in which you are
                resident in.
              </p>
              <h5>United States Federal Government End Use Provisions</h5>
              <p>
                If You are a U.S. federal government end user, our Service is a
                “Commercial Item” as that term is defined at 48 C.F.R. §2.101.
              </p>
              <h5>Severability and Waiver</h5>
              <h6>SEVERABILITY</h6>
              <p>
                If any provision of these Terms is held to be unenforceable or
                invalid, such provision will be changed and interpreted to
                accomplish the objectives of such provision to the greatest
                extent possible under applicable law and the remaining
                provisions will continue in full force and effect.
              </p>
              <h6>WAIVER</h6>
              <p>
                Except as provided herein, the failure to exercise a right or to
                require performance of an obligation under this Terms shall not
                effect a party’s ability to exercise such right or require such
                performance at any time thereafter nor shall be the waiver of a
                breach constitute a waiver of any subsequent breach.
              </p>
              <h5>Changes to These Terms and Conditions</h5>
              <p>
                We reserve the right, at Our sole discretion, to modify or
                replace these Terms at any time. If arevision is material We
                will make reasonable efforts to provide at least 30 days’ notice
                prior to any new terms taking effect. What constitutes a
                material change will be determined at Our solediscretion.
              </p>
              <p>
                By continuing to access or use Our Service after those revisions
                become effective, You agree to be bound by the revised terms. If
                You do not agree to the new terms, in whole or in part, please
                stop using the website and the Service.
              </p>
              <h5>Contact Us</h5>
              <p>
                If you have any questions about these Terms and Conditions, You
                can contact us:
                <a href='mailto:tambe.egypt@gmail.com'>tambe.egypt@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditionDetails;
