/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Input,
  CardBody,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import {
  createUser,
  getUser,
  updateUser,
} from "../../services/authentication.service";
import DropZone from "../../common/dropzone";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ToastShow } from "../../redux/toast/toast.action";

const UserForm = (props) => {
  const { history, match, setToast } = props;
  const userId = match?.params?.id;

  const phoneRegExp = /^\d{11}$/;
  const passwordRegExp =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(-1);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setId(props.match.params.id);
      getUserData(props.match.params.id);
    }
  }, [props.match]);

  const getUserData = async (id) => {
    const data = await getUser(id);
    const response = data?.data?.data;
    if (response.length === 0) return history.push("/user");
    const { name, phone_number, ...otherData } = response;

    if (response) {
      if (response.user_image || response.user_background_image) {
        setInitialValues({
          name: name,
          phone_number: phone_number,
          email: otherData?.email ? otherData.email : "",
          password: "",
          confirm_password: "",
          user_image: response.user_image
            ? [
                {
                  name: response.name,
                  preview: process.env.REACT_APP_API_URL + response.user_image,
                },
              ]
            : [],
          user_background_image: response.user_background_image
            ? [
                {
                  name: response.name,
                  preview:
                    process.env.REACT_APP_API_URL +
                    response.user_background_image,
                },
              ]
            : [],
        });
      } else {
        setInitialValues({
          name: name,
          phone_number: phone_number,
          email: otherData?.email ? otherData.email : "",
          password: "",
          confirm_password: "",
          user_image: [],
          user_background_image: [],
        });
      }
    }
  };

  const addValue = {
    name: "",
    phone_number: "",
    email: "",
    password: "",
    confirm_password: "",
    user_image: [],
    user_background_image: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(30, "Name is atleast 30 word")
      .required("name is required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("phone number is required"),
    email: Yup.string().email("Email is not valid"),
  });

  const passwordValidationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Minimum 6 word")
      .max(16, "Maximum 16 word")
      .matches(passwordRegExp, "Password is not stronger"),
    // .required('password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Password dose not match")
      .when("password", {
        is: (password) => (password ? password.length > 0 : false),
        then: Yup.string().required("Please enter password"),
      }),
    // .required('Confirm password is required'),
  });

  const mergeSchema = validationSchema.concat(passwordValidationSchema);

  const onSubmit = async (values, otherOptions) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("phone_number", values.phone_number);
    formData.append("password", values.password);
    if (values.email) {
      formData.append("email", values.email);
    }
    if (values.user_background_image[0]) {
      formData.append("user_background_image", values.user_background_image[0]);
    }
    if (values.user_image[0]) {
      formData.append("user_image", values.user_image[0]);
    }

    if (id !== -1) {
      const res = await updateUser(userId, formData);
      if (res.data.data.length === 0) {
        setToast(res.data.message);
        setLoader(false);
        return;
      }
      setToast("User updated!!!");
      history.push("/user");
    } else {
      const res = await createUser(formData);
      if (res.data.data.length === 0) {
        setToast(res.data.message);
        setLoader(false);
        return;
      }
      setToast("User added!!!");
      history.push("/user");
    }

    otherOptions.resetForm();
  };

  const [initialValues, setInitialValues] = useState(addValue);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={/* id === -1 ?  */ mergeSchema /* : validationSchema */}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        return (
          <div className="content-wrapper">
            <Form>
              <CardBody>
                <Row>
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <Label htmlFor="name">Name</Label>
                      <Field
                        as={Input}
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <Label htmlFor="phone_name">Phone Number</Label>
                      <Field
                        as={Input}
                        type="text"
                        placeholder="Enter Phone Number"
                        name="phone_number"
                      />
                      <ErrorMessage name="phone_number">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <Label htmlFor="email">Email</Label>
                      <Field
                        as={Input}
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                      />
                      <ErrorMessage name="email">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor="password">Password</Label>
                      <Field
                        as={Input}
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                      />
                      <ErrorMessage name="password">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor="confirm_password">Confirm Password</Label>
                      <Field
                        as={Input}
                        type="password"
                        placeholder="Enter Confirm Password"
                        name="confirm_password"
                      />
                      <ErrorMessage name="confirm_password">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor="background image">User Profile</Label>
                      <InputGroup>
                        <DropZone
                          name="user_image"
                          files={values.user_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor="background image">
                        User Cover Background
                      </Label>
                      <InputGroup>
                        <DropZone
                          name="user_background_image"
                          files={values.user_background_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <div className="card-footer text-center">
                <Button
                  type="submit"
                  color="primary"
                  disabled={loader ? true : false}
                >
                  {loader ? (
                    <i className="fas fa-3x fa-sync-alt fa-spin mr-2"></i>
                  ) : (
                    ""
                  )}
                  {id === -1 ? <>Add User</> : <>Update User</>}
                </Button>
                <span className="ml-3">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      history.push("/user");
                    }}
                  >
                    Back
                  </Button>
                </span>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(null, mapDispatchToProps)(UserForm);
