import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Label, Button } from 'reactstrap';
import { getUser } from '../../services/authentication.service';

const UserView = (props) => {
  let history = useHistory();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      getUserData(props.match.params.id);
    }
  }, [props.match]);

  const getUserData = async (id) => {
    const data = await getUser(id);
    const response = data?.data?.data;
    setUserData(response);
  };

  return (
    <div className='content-wrapper'>
      <div className='user-profile-wrap'>
        <div className='background-image'>
          <Card>
            <img
              src={
                userData.user_background_image
                  ? process.env.REACT_APP_API_URL +
                    userData.user_background_image
                  : '/images/user_bg_img.jpg'
              }
              alt='CoverImage'
            />
          </Card>
        </div>
        <div className='user-img-wrap'>
          <Card>
            <img
              src={
                userData.user_image
                  ? process.env.REACT_APP_API_URL + userData.user_image
                  : '/images/user_profile.jpg'
              }
              alt='Profile'
            />
          </Card>
        </div>
        <div className='user-detail-wrap'>
          <div className='user-name'>
            <Label>Name:</Label>
            <p>{userData.name}</p>
          </div>
          <div className='user-phone'>
            <Label>Phone:</Label>
            <p>{userData.phone_number}</p>
          </div>
          <div className='user-email'>
            <Label>Email:</Label>
            <p>{userData.email ? userData.email : '-'}</p>
          </div>
          <div className='card-footer'>
            <span>
              <Button
                type='button'
                color='secondary'
                onClick={() => {
                  history.push('/user');
                }}
              >
                Back
              </Button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
