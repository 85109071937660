import React from "react";

import Header from "./default.header";
import SideBar from "./default.sidebar";
import Footer from "./default.footer";

const DefaultLayout = (props) => {
  return (
      <div className="wrapper">
        <Header />
        <SideBar />
        {props.children}
        <Footer />
      </div>
  );
};

export default DefaultLayout;
