/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  getDashboardCount,
  getProductData,
  getStoreData,
} from "../../services/dashboard.service";

const Dashboard = () => {
  const yearOption = [];

  for (let i = new Date().getFullYear(); i >= 2015; i--) {
    yearOption.push({
      value: i,
      label: i,
    });
  }

  const [count, setCount] = useState({
    userCount: 0,
    storeCount: 0,
    productCount: 0,
  });
  const [productGraphLabel, setProductGraphLabels] = useState([]);
  const [productGraphData, setProductGraphData] = useState([]);
  const [storeGraphLabel, setStoreGraphLabel] = useState([]);
  const [storeGraphData, setStoreGraphData] = useState([]);
  const [productYearDropdown, setProductYearDropdown] = useState(
    new Date().getFullYear()
  );
  const [storeYearDropdown, setStoreYearDropdown] = useState(
    new Date().getFullYear()
  );

  const getCount = async () => {
    const countData = await getDashboardCount();
    setCount(countData.data.data);
  };

  const getProduct = async (year) => {
    const productData = await getProductData({ year });
    setProductGraphLabels(productData.data.data.chartLabels);
    setProductGraphData(productData.data.data.chartData);
  };

  const getStore = async (year) => {
    const storeData = await getStoreData({ year });
    setStoreGraphLabel(storeData.data.data.chartLabels);
    setStoreGraphData(storeData.data.data.chartData);
  };

  useEffect(() => {
    getCount();
  }, []);

  useEffect(() => {
    getProduct(productYearDropdown);
  }, [productYearDropdown]);

  useEffect(() => {
    getStore(storeYearDropdown);
  }, [storeYearDropdown]);

  const productData = {
    labels: productGraphLabel,
    datasets: [
      {
        label: "Product",
        data: productGraphData,
        fill: true,
        lineTension: 0.3,
        borderWidth: 3,
        borderColor: "#742774",
        pointRadius: 2,
        pointBackgroundColor: "#742774",
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#742774",
        pointHitRadius: 10,
      },
    ],
  };

  const storeData = {
    labels: storeGraphLabel,
    datasets: [
      {
        label: "Store",
        data: storeGraphData,
        backgroundColor: "#742774",
        borderColor: "#742774",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="widget_main">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <Link to="/user" target="">
                  <div className="widget-dashboard widget-bg-4">
                    <h4>User Count</h4>
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <h1>{count.userCount}</h1>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 col-sm-12">
                <Link to="/store" target="">
                  <div className="widget-dashboard widget-bg-4">
                    <h4>Store Count</h4>
                    <i className="fa fa-store" aria-hidden="true"></i>
                    <h1>{count.storeCount}</h1>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 col-sm-12">
                <Link to="/product" target="">
                  <div className="widget-dashboard widget-bg-4">
                    <h4>Product Count</h4>
                    <i className="fab fa-product-hunt" aria-hidden="true"></i>
                    <h1>{count.productCount}</h1>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 col-sm-12">
                <Link to="/category" target="">
                  <div className="widget-dashboard widget-bg-4">
                    <h4>Category Count</h4>
                    <i className="fa fa-list-alt" aria-hidden="true"></i>
                    <h1>{count.productCategoryCount}</h1>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 col-sm-12">
              <Select
                className="ml-3"
                placeholder="Select Store"
                options={yearOption}
                defaultValue={yearOption[0]}
                onChange={(e) => {
                  setProductYearDropdown(e.value);
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <Select
                className="ml-3"
                placeholder="Select Store"
                options={yearOption}
                defaultValue={yearOption[0]}
                onChange={(e) => {
                  setStoreYearDropdown(e.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Line
                data={productData}
                options={{
                  plugins: {
                    legend: false,
                    title: {
                      text: "Product",
                      display: true,
                      color: "#742774",
                      position: "bottom",
                      font: { size: 20 },
                    },
                  },
                  scales: {
                    x: { grid: { display: false } },
                    y: { grid: { display: false }, min: 0 },
                  },
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <Bar
                data={storeData}
                options={{
                  plugins: {
                    legend: false,
                    title: {
                      text: "Store",
                      display: true,
                      color: "#742774",
                      position: "bottom",
                      font: { size: 20 },
                    },
                  },
                  scales: {
                    x: { grid: { display: false } },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
