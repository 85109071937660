/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectThemeSetting } from "../../redux/theme_setting/theme.selector";

const SideBar = ({ theme }) => {
  return (
    <aside className="main-sidebar sidebar-dark-primary sidebar-menu">
      <Link to="/" className="brand-link">
        <img
          src={
            theme?.logo_image
              ? process.env.REACT_APP_API_URL + theme.logo_image
              : "/images/Tambe_Logo-light-1.png"
          }
          alt="Logo"
          className="brand-logo"
          style={{ opacity: 0.8 }}
        />
      </Link>
      {/* <span className='brand-text font-weight-light'>TamBE</span> */}

      <div className="sidebar">
        <nav className="">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink
                to="/"
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/product" exact className="nav-link">
                <i className="nav-icon fab fa-product-hunt"></i>
                <p>Product</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/store" exact className="nav-link">
                <i className="nav-icon fas fa-store"></i>
                <p>Store</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/category" exact className="nav-link">
                <i className="nav-icon fas fa-list-alt"></i>
                <p>Category</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/user" exact className="nav-link">
                <i className="nav-icon fas fa-user"></i>
                <p>User</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Dynamic Content</p>
                <i className="right fas fa-angle-left mt-2"></i>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink to="/aboutUs" exact className="nav-link">
                    <i className="nav-icon fas fa-info-circle"></i>
                    <p>About Us</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/terms-condition" exact className="nav-link">
                    <i className="nav-icon fas fa-shield-alt"></i>
                    <p>Terms & Condition</p>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

const mapStateToProps = createStructuredSelector({
  theme: selectThemeSetting,
});

export default connect(mapStateToProps)(SideBar);
