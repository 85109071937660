/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  CardBody,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ToastShow } from '../../redux/toast/toast.action';
import {
  addAboutContent,
  getAboutContent,
} from '../../services/aboutus.service';
import { connect } from 'react-redux';

const initial_state = {
  content: '',
};

const AboutUs = (props) => {
  const { setToast } = props;
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(initial_state);

  useEffect(() => {
    getAbout();
  }, []);

  const getAbout = async () => {
    const res = await getAboutContent();
    if (res.data.response_type === 'success') {
      const data = res.data.data;
      if (data !== []) {
        setInitialValues({
          ...initialValues,
          content: data.content ? data.content : '',
        });
      }
    }
  };

  const submitEvent = async (value) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('content', value.content);

    const res = await addAboutContent(formData);
    if (res.data.response_type === 'success') {
      setToast('About Us Updated!!!');
      //   setThemeSetting(res.data.data);
      history.push('/');
    }
    setLoader(false);
    setToast(res.data.message);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        submitEvent(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className='content-wrapper'>
            <Form>
              <CardBody>
                <Row>
                  <Col sm={12} md={12}>
                    <FormGroup>
                      <Label htmlFor='Content'>About Us</Label>
                      <Input
                        type='textarea'
                        placeholder='About Us Content'
                        name='content'
                        style={{ border: '1px solid black' }}
                        value={values.content}
                        rows={5}
                        onChange={(e) =>
                          setFieldValue('content', e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <div className='card-footer text-center'>
                <Button
                  type='submit'
                  color='primary'
                  disabled={loader ? true : false}
                >
                  {loader ? (
                    <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                  ) : (
                    ''
                  )}
                  Update
                </Button>
                <span className='ml-3'>
                  <Button
                    type='button'
                    color='secondary'
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Back
                  </Button>
                </span>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(null, mapDispatchToProps)(AboutUs);
