import { axiosGet } from "../axios/config";

export const getDashboardCount = (data) => {
  return axiosGet(`/dashboard/count`, data);
};

export const getProductData = (data) => {
  return axiosGet(`/dashboard/product`, data);
};

export const getStoreData = (data) => {
  return axiosGet('/dashboard/store',data);
}
