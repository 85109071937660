/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Label, Button } from "reactstrap";
import { getParticularProduct } from "../../services/product.service";
import Cursoal from "../../common/slider/index";
import { getAllProductCategory } from "../../services/category.service";

const ProductView = props => {
    let history = useHistory();
    const [image, setImage] = useState([]);
    const [productData, setProductData] = useState([]);
    const [productCategory, setProductCategory] = useState([]);

    useEffect(() => {
        if (props?.match?.params.id) {
            getProduct(props.match.params.id);
        }
    }, [props.match]);

    const getProduct = async id => {
        const res = await getParticularProduct(id);
        const data = res.data.data;
        setProductData(data);
        let imageTmp = [];
        res.data?.data?.product_image.map((e, index) =>
            imageTmp.push({
                src: process.env.REACT_APP_API_URL + e,
                altText: "Product Image"
            })
        );
        setImage([...imageTmp]);

        await fetchCategory();
    };

    const fetchCategory = async () => {
        const res = await getAllProductCategory();
        const options = res.data.data;
        let comboOptions = [];
        options.forEach(({ key, value }) =>
            comboOptions.push({
                value: value,
                label: key
            })
        );
        // originalCategoiresComboOption = comboOptions;
        setProductCategory(comboOptions);
    };

    return (
        <div className="content-wrapper">
            <div className="product-detail-wrap">
                <div className="product-images">
                    {image.length > 0 && <Cursoal image={image} />}
                </div>
                <div className="product-content-wrap">
                    <div className="product-name mt-5">
                        <Label>Name:</Label>
                        <p>{productData.product_name}</p>
                    </div>
                    <div className="product-desc">
                        <Label>Description:</Label>
                        <p>{productData.product_desc}</p>
                    </div>
                    <div className="product-price">
                        <Label>Price:</Label>
                        <p>{productData.product_price}</p>
                    </div>
                    <div className="product-material">
                        <Label>Material:</Label>
                        <p>{productData.product_material ? productData.product_material : "-"}</p>
                    </div>
                    <div className="product-color">
                        <Label>Color:</Label>
                        <p>{productData.product_colors ? productData.product_colors : "-"}</p>
                    </div>
                    <div className="product-size">
                        <Label>Size:</Label>
                        <p>
                            {productData.product_size && productData.product_size.length > 0
                                ? productData.product_size.join(", ")
                                : "-"}
                        </p>
                    </div>
                    <div className="product-size">
                        <Label>Product Category:</Label>
                        <p>
                            {productData.product_category && productData.product_category.length > 0
                                ? productCategory
                                      .filter(e => {
                                          return productData.product_category.find(
                                              e1 => e.value === e1
                                          );
                                      })
                                      .map(e2 => e2.label)
                                      .join(", ")
                                : "-"}
                        </p>
                    </div>
                    <div className="product-link">
                        {productData.product_link ? (
                            <>
                                <Label>
                                    <i className="fas fa-globe"></i>
                                </Label>
                                <p>
                                    <a href="">
                                        {productData.product_link ? productData.product_link : "-"}
                                    </a>
                                </p>
                            </>
                        ) : null}
                    </div>
                    <div className="card-footer">
                        <span>
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => {
                                    history.push("/product");
                                }}
                            >
                                Back
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductView;
