import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../axios/config";

export const createProduct = data => {
    return axiosPost("/product/add", data);
};

export const getAllProduct = data => {
    return axiosGet("/product/admin/", data);
};

export const getAllProducts = data => {
    return axiosGet("/product/list", data);
};

export const getParticularProduct = data => {
    return axiosPost(`/product/view/${data}`);
};

export const scrapProduct = async data => {
    for (let pair of data) {
        console.log(pair[0] + ":", pair[1]);
    }
    const res = await axiosPost(`/product/scrap`, data);
    return axiosPost(`/product/add_scrap_product`, res.data.data);
};

export const deleteProduct = data => {
    return axiosDelete(`/product/delete/${data}`);
};

export const updateProduct = (id, data) => {
    return axiosPatch(`/product/update/${id}`, data);
};
