/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Input,
  InputGroup,
  Card,
  CardBody,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  FormGroup,
} from 'reactstrap';
import {
  getCurrentuser,
  login,
} from '../../../services/authentication.service';
import { selectToken } from '../../../redux/token/token.selector';
import { selectThemeSetting } from '../../../redux/theme_setting/theme.selector';
import { createStructuredSelector } from 'reselect';
import { setToken } from '../../../redux/token/token.action';
import { ToastShow } from '../../../redux/toast/toast.action';
import { forgetPassword } from '../../../services/forget_password.service';
import { setCurrentUser } from '../../../redux/user/user.action';

const Login = ({ setToken, setToast, token, setCurrentUser, theme }) => {
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [forgetPasswordLink, setForgetPasswordLink] = useState('');
  const [toggleLink, setToggleLink] = useState(false);
  const [mailLoader, setMailLoader] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (token) {
      history.push('/');
    }
  }, []);

  const initialValues = {
    phone_number: '',
    password: '',
  };

  const initialForget = {
    userName: '',
  };

  // const phoneRegExp = /^\d{10}$/;
  const EmailRegExp = /^(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3})/;

  const validation = Yup.object({
    phone_number: Yup.string()
      // .matches(passwordOrEmailRegExp, 'Field is not valid')
      .required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const validationSchemaForget = Yup.object().shape({
    userName: Yup.string()
      .matches(EmailRegExp, 'Please enter valid email')
      .required('Field is required!'),
  });

  const submitEvent = (values) => {
    setLoader(true);
    const user = {
      phone_number: values.phone_number,
      password: values.password,
    };
    login(user).then(async (res) => {
      if (res.data.response_type !== 'error') {
        if (res.data.data.length !== 0) {
          console.log(res.data.data.length);
          setToken(res.data.data);
          const data = await getCurrentuser();
          setCurrentUser(data.data.data);
          setLoader(false);
          history.push('/');
        }
      } else {
        if (res.data.message) {
          setToast(res.data.message);
          setLoader(false);
        }
      }
    });
  };

  const forgetPasswordSubmit = async (values) => {
    setMailLoader(true);
    const res = await forgetPassword(values);
    if (res.data.response_type === 'success') {
      setForgetPasswordLink(res.data.data.link);
      setToggleLink(true);
      setToggle(false);
    }
    setToast(res.data.message);
    setMailLoader(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => {
          submitEvent(values);
        }}
      >
        {({ errors, touched }) => {
          return (
            <div className='login-screen'>
              <div className='wrapper'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12 left-img-wrap'>
                    <img
                      src={
                        theme?.banner_image
                          ? process.env.REACT_APP_API_URL + theme.banner_image
                          : '/images/Tambe_app_icn.png'
                      }
                      alt='logo'
                    ></img>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='hold-transition login-page'>
                      <div className='login-box'>
                        <div className='login-logo'>
                          <img src='/images/tambe_logo_2.png' alt='logo'></img>
                        </div>
                        <Card>
                          <CardBody className='login-card-body'>
                            <p className='login-box-msg'>Log-In</p>
                            <Form>
                              <InputGroup className='mb-3'>
                                <Field
                                  as={Input}
                                  type='text'
                                  placeholder='Phone Number or Email'
                                  name='phone_number'
                                  invalid={
                                    touched.phone_number &&
                                    !!errors.phone_number
                                  }
                                />
                                <div className='input-group-append'>
                                  <div className='input-group-text'>
                                    <span className='fas fa-envelope'></span>
                                  </div>
                                </div>
                              </InputGroup>

                              <InputGroup className='mb-3'>
                                <Field
                                  as={Input}
                                  type='password'
                                  placeholder='Password'
                                  name='password'
                                  invalid={
                                    touched.password && !!errors.password
                                  }
                                />
                                <div className='input-group-append'>
                                  <div className='input-group-text'>
                                    <span className='fas fa-lock'></span>
                                  </div>
                                </div>
                              </InputGroup>
                              <div className='row'>
                                <div className='col-6'>
                                  <Button
                                    color='primary'
                                    block
                                    type='submit'
                                    disabled={loader ? true : false}
                                  >
                                    {loader ? (
                                      <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                                    ) : (
                                      ''
                                    )}
                                    Sign In
                                  </Button>
                                </div>
                                <div className='col-6'>
                                  <div className='mt-2 text-right'>
                                    <p className='mb-1'>
                                      <a
                                        href='#'
                                        onClick={() => setToggle(!toggle)}
                                      >
                                        Forgot Password?
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <Modal
        isOpen={toggle}
        toggle={() => setToggle(!toggle)}
        className={'modal-primary '}
      >
        <ModalHeader toggle={() => setToggle(!toggle)}>
          <div className='modal_title'>
            <i className='fa fa-undo'></i> Forgot Password
          </div>
        </ModalHeader>
        <Formik
          initialValues={initialForget}
          validationSchema={validationSchemaForget}
          onSubmit={forgetPasswordSubmit}
        >
          {({ touched, errors }) => (
            <Form className='form'>
              <ModalBody>
                <FormGroup>
                  <Field
                    as={Input}
                    type='text'
                    name='userName'
                    placeholder='Email'
                    invalid={touched.email && !!errors.email}
                  />
                  <ErrorMessage name='userName'>
                    {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
                  </ErrorMessage>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  type='submit'
                  color='primary'
                  disabled={mailLoader ? true : false}
                >
                  {mailLoader ? (
                    <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                  ) : (
                    ''
                  )}
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        isOpen={toggleLink}
        toggle={() => setToggleLink(!toggleLink)}
        className={'modal-primary '}
      >
        <ModalHeader toggle={() => setToggleLink(!toggleLink)}>
          <div className='modal_title'>
            <i className='fa fa-envelope'></i> Mail Sent
          </div>
        </ModalHeader>

        <ModalBody>Your link is send in your email.</ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectToken,
  theme: selectThemeSetting,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  setToast: (message) => dispatch(ToastShow({ message })),
  setCurrentUser: (data) => dispatch(setCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
