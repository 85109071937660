/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Label, Button } from 'reactstrap';
import { getParticularStore } from '../../services/store.service';

const StoreView = (props) => {
  let history = useHistory();
  const [storeData, setStoreData] = useState([]);

  useEffect(() => {
    if (props?.match?.params.id) {
      getStore(props.match.params.id);
    }
  }, [props.match]);

  const getStore = async (id) => {
    const res = await getParticularStore(id);
    delete res.data.data['product'];

    const data = res.data.data;
    setStoreData(data);
  };

  return (
    <div className='content-wrapper'>
      <div className='store-detail-wrap'>
        <div className='background-image'>
          <Card>
            <img
              src={
                storeData.store_background_image
                  ? process.env.REACT_APP_API_URL +
                    storeData.store_background_image
                  : '/images/store_bg_img.jpg'
              }
            />
          </Card>
        </div>
        <div className='store-content'>
          <div className='logo-image'>
            <Card
              style={{ height: '100px', width: '100px', borderRadius: '10px' }}
            >
              <img
                src={
                  storeData.store_image
                    ? process.env.REACT_APP_API_URL + storeData.store_image
                    : ''
                }
              />
            </Card>
          </div>
          <div className='store-detail-content'>
            <div className='store-name'>
              <Label>Name:</Label>
              <p>{storeData.store_name}</p>
            </div>
            <div className='store-desc'>
              <Label>Description:</Label>
              <p>{storeData.store_desc}</p>
            </div>
            <div className='store-link'>
              {storeData.store_link ? (
                <>
                  <Label>
                    <i className='fas fa-globe'></i>
                  </Label>
                  <p>
                    <a href=''>
                      {storeData.store_link ? storeData.store_link : null}
                    </a>
                  </p>
                </>
              ) : null}
            </div>
            <div className='card-footer'>
              <span>
                <Button
                  type='button'
                  color='secondary'
                  onClick={() => {
                    history.push('/store');
                  }}
                >
                  Back
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreView;
